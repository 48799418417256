.App {
  text-align: center;
  width: 100%;
  font-family: Noto Ser;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  opacity: 0.5;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  overflow: scroll;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.whitepaper-comp {
  padding: 5em;
}
.App-link {
  color: #61dafb;
}
.pdf-comp {
  width: 100%;
  height: 100vh;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.body-container {
  height: fit-content;
  margin-top: 64px;
}
.body-container-home {
  height: fit-content;
}
.navbar-menu {
  position: fixed;
  display: flex;
  width: 100%;
  height: 64px;
  text-decoration: none;
  justify-content: space-between;
}
.navbar-menu-color {
  position: fixed;
  display: flex;
  width: 100%;
  height: 64px;
  top: 0px;
  text-decoration: none;
  justify-content: space-between;
  right: 0;
  background-color: rgba(0, 0, 0, 0.95);
}
.menu-icon {
  display: none;
}
.links-list {
  display: flex;
  color: #fff;
  justify-content: end;
  list-style: none;
}
.navbar-menu .link-menu {
  display: flex;
  justify-content: right;
  padding: 12px;
  text-decoration: none;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
}
.link-menu {
  display: flex;
  justify-content: right;
  padding: 12px;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}
.navbar-menu-color .link-menu {
  display: flex;
  justify-content: right;
  padding: 12px;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
}
.link-menu-active {
  display: flex;
  color: rgb(162, 160, 160);
  padding: 12px;
  text-decoration: none;
  text-transform: uppercase;
}
.link-menu-active:hover {
  color: rgb(162, 160, 160);
}
.link-menu:hover {
  color: rgb(162, 160, 160);
}
.image-home {
  width: 100%;
  height: 100vh;
  align-items: center;
  background-position: center;
  background-size: cover;
  padding: 0;
}
.logo {
  width: auto;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
}
.logo-menu {
  list-style: none;
}
.container {
  width: -webkit-fill-available;
}
.section-text {
  width: 100%;
  height: fit-content;
  padding-top: 24px;
  padding-bottom: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
}
.first-item {
  width: 100%;
  height: fit-content;
  display: block;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 24px;
  padding-top: 48px;
  padding-bottom: 48px;
}
.block-component {
  display: flex;
}
.second-item {
  width: 100%;
  height: fit-content;
  display: block;
  justify-content: end;
  align-items: center;
  margin-bottom: 24px;
}
.icon {
  width: 200px;
  height: 200px;
}

.text-element {
  width: 50%;
  text-justify: auto;
  font-weight: 500;
  text-align: start;
}
.text-title {
  padding-top: 0;
}
.section-title {
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.95);
}
.first-item .icon-component {
  display: flex;
  justify-content: center;
  width: 50%;
}
.second-item .icon-component {
  display: flex;
  justify-content: center;
  width: 50%;
}
.first-item .text-component {
  display: block;
  text-align: -webkit-center;
  margin-top: auto;
  margin-bottom: auto;
  width: 50%;
}
.second-item .text-component {
  display: block;
  text-align: -webkit-center;
  margin-top: auto;
  margin-bottom: auto;
  width: 50%;
}
.footer-component {
  width: 100%;
  height: fit-content;
  background-color: #000;
  position: relative;
  margin-top: 100px;
}
.footer-component-opened {
  width: 100%;
  height: fit-content;
  background-color: #000;
  position: relative;
  margin-top: 100px;
  z-index: -1;
  opacity: 0.5;
}
.empire-building {
  position: absolute;
  top: -80px;
  width: 5%;
  height: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hidden-text {
  display: none;
}
.text-btn {
  background-color: #fff;
  border: none;
  color: rgba(0, 0, 0, 0.95);
  text-decoration: underline;
  list-style: none;
}
.text-btn {
  background-color: #fff;
  border: none;
  color: rgba(0, 0, 0, 0.95);
  text-decoration: underline;
  list-style: none;
}
.logo-component li {
  list-style: none;
}
.text-element li {
  list-style: none;
}
.text-btn:hover {
  color: rgb(162, 160, 160);
}
.links-title {
  color: #fff;
  display: block;
}
.links-list-block {
  display: flex;
  margin-left: 0;
  list-style: none;
  flex-direction: column;
  align-items: flex-start;
}
.links-list-block li {
  width: 100%;
}
.links-list-block a {
  width: 100%;
}

.links-group {
  display: flex;
  justify-content: space-evenly;
  padding: 24px;
}
.links-group .links-title {
  display: flex;
  justify-content: flex-start;
}
.logo-component .footer-logo {
  display: none;
}
.footer-logo-desktop {
  width: 150px;
  height: 150px;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 200px;
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
.navbar-menu-mob {
  display: none;
}
.navbar-menu-color-mob {
  display: none;
}
.title-page {
  color: #000;
  font-weight: bold;
  text-align: center;
  padding-top: 24px;
  padding: 12px;
}
.card-title {
  font-size: 2em;
  text-align: center;
  text-decoration: none;
}
.download-link {
  text-decoration: none;
}
.cards-download {
  padding: 32px;
  display: flex;
  justify-content: center;
}
.card-box {
  display: block;
  margin: auto;
  z-index: -1;
  background-color: #000;
  color: #ffffff;
  width: 100%;
  height: 100%;
}
.card-img {
  width: 100px;
  display: block;
  margin: auto;
  padding: 20px;
}
.card-img-smll {
  width: 200px;
  display: block;
  margin: auto;
  padding: 20px;
}
.btn-slideshow {
  background: #fff;
  border: #fff;
  display: block;
  font-weight: bold;
  width: fit-content;
  text-decoration: none;
  margin: auto;
  color: #000;
  box-shadow: 1px 2px 2px #0000006e;
}
.btn-slideshow:hover {
  background-color: #f3ce7d;
  color: #fff;
  box-shadow: 1px 2px 2px #0000006e;
}
#close-icon {
  display: none;
}
.email-link-opened h4 {
  color: #000;
  font-weight: bold;
  pointer-events: none;
  opacity: 0.5;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  display: none;
}
.email-link h4 {
  color: #000;
  font-weight: bold;
  display: block;
  align-items: center;
  text-align: center;
  padding: 12px;
  margin-left: auto;
  margin-right: auto;
}
.email-h4 {
  text-align: center;
  color: #000;
  padding: 12px;
  font-size: 1em;
}
.email-link {
  text-decoration: none;
  text-align: center;
}
.email-link-opened {
  text-decoration: none;
  text-align: center;
  pointer-events: none;
  display: none;
  opacity: 0.5;
}
.email-link:hover {
  text-decoration: underline;
  font-weight: bold;
  color: #000;
}
.info-body {
  background-color: #fff;
  padding: 24px;
  align-items: center;
  height: 100vh;
  margin-bottom: 2em;
  font-size: calc(10px + 2vmin);
  color: white;
  font-size: calc(10px + 2vmin);
  color: white;
}
.email-txt {
  color: #000;
  display: block;
  text-decoration: none;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
}
.email-txt-opened {
  color: #000;
  display: none;
  text-decoration: none;
  align-items: center;
  padding: 12px;
  pointer-events: none;
  opacity: 0.5;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.email-txt:hover {
  color: #ffffff;
  font-weight: bold;
}
.terms-service-tittle {
  color: #000;
  font-weight: bold;
  text-align: center;
}
.rights-reserved {
  margin-top: 32px;
  color: #000;
  font-size: small;
  font-weight: bold;
  text-align: center;
}
.terms-service {
  display: block;
  color: #000;
  margin: 24px auto;
  text-align: left;
  text-indent: 2em;
  padding: auto;
  width: 70%;
}
.terms-service p {
  font-size: 0.75em;
}
.text-footer {
  text-align: center;
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 24px;
}
.instructions-list {
  padding: 48px;
}
.projects-component {
  width: 100%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.95);
  padding: 24px;
  border-bottom: 1px solid rgb(162, 160, 160);
}
.projects-block li {
  list-style: none;
  color: #fff;
  text-decoration: none;
  text-align: center;
}
.projects-title {
  text-align: center;
  color: #fff;
}
.projects-block {
  display: flex;
  justify-content: space-evenly;
  padding: 32px;
}
.projects-component a {
  color: #fff;
  text-decoration: none;
  text-align: center;
}
.mgc-logo {
  width: 4em;
  height: 4em;
}
.trivia-logo {
  width: 4em;
  height: 4em;
}
.links-group li {
  list-style: none;
}
.link-footer {
  display: block;
}
/*Scroll animation home image*/
/*
.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 40px;
  height: 40px;
  top: 50%;
  margin: -20px 0 0 -20px;
  transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
}

.arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}
@keyframes arrow {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}
*/
.c-scroll-icon-none {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.links-list-opened .c-scroll-icon {
  width: 21px;
  height: 100px;
  z-index: -1;
  transform: translate3d(-50%, 0%, 0);
  position: absolute;
  left: 50%;
  bottom: 100px;
  backface-visibility: hidden;
}

.c-scroll-icon {
  width: 21px;
  height: 100px;
  transform: translate3d(-50%, 0%, 0);
  position: absolute;
  left: 50%;
  bottom: 100px;
  backface-visibility: hidden;
}

.c-scroll-icon-line-mask {
  position: relative;
  overflow: hidden;
  width: 21px;
  height: 100px;
  margin: 0 auto;
}
.c-scroll-icon-line {
  position: absolute;
  display: inline-block;
  width: 1px;
  height: 150px;
  border-left: 1px solid #fff;
  top: -150px;
  left: 10px;
  animation: scroll-line 3s infinite;
  transition: all cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@keyframes scroll-line {
  0% {
    transform: translate3d(0, 0, 0);
  }
  40% {
    transform: translate3d(0, 185px, 0);
  }
  75% {
    transform: translate3d(0, 175px, 0);
  }
  100% {
    transform: translate3d(0, 280px, 0);
  }
}

.c-scroll-icon-triangle {
  position: relative;
  width: 20px;
  height: 30px;
  margin: -10px auto 0;
  animation: triangle-bounce 3s infinite;
}

.c-scroll-icon-triangle-mask {
  position: absolute;
}

.c-scroll-icon-triangle-mask.first {
  width: 40px;
  height: 5px;
  right: 0;
  overflow: hidden;
}

.c-scroll-icon-triangle-mask.right {
  width: 10px;
  height: 30px;
  overflow: hidden;
  right: 0;
}

.c-scroll-icon-triangle-mask.left {
  width: 10px;
  height: 30px;
  overflow: hidden;
  left: 1;
}

.c-scroll-icon-triangle-mask.last {
  width: 40px;
  height: 5px;
  overflow: hidden;
}

.c-scroll-icon-triangle-line {
  position: absolute;
}

.c-scroll-icon-triangle-line.first {
  border-bottom: 1px solid #fff;
  width: 30px;
  right: 0;
  animation: triangle-first 3s infinite;
  transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.c-scroll-icon-triangle-line.right {
  border-left: 1px solid #fff;
  height: 44px;
  transform: rotate(-163deg);
  right: 5px;
  top: -6px;
  animation: triangle-right 3s infinite;
  transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.c-scroll-icon-triangle-line.left {
  border-left: 1px solid #fff;
  height: 40px;
  transform: rotate(163deg);
  left: 5px;
  top: -3px;
  animation: triangle-left 3s infinite;
  transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.c-scroll-icon-triangle-line.last {
  border-bottom: 1px solid #fff;
  width: 120px;
  left: 0;
  animation: triangle-last 3s infinite;
  transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@keyframes triangle-bounce {
  0% {
    transform: translate3d(0, -5px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
  100% {
    transform: translate3d(0, -5px, 0);
  }
}

@keyframes triangle-first {
  0%,
  20% {
    transform: translate3d(-40px, 0, 0);
  }
  30%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
  85%,
  100% {
    transform: translate3d(30px, 0, 0);
  }
}

@keyframes triangle-right {
  0%,
  25% {
    transform: rotate(-163deg) translate3d(0, 40px, 0);
  }
  40%,
  84% {
    transform: rotate(-163deg) translate3d(0, 0, 0);
  }
  90%,
  100% {
    transform: rotate(-163deg) translate3d(0, -37px, 0);
  }
}

@keyframes triangle-left {
  0%,
  33% {
    transform: rotate(163deg) translate3d(0, -34px, 0);
  }
  50%,
  88% {
    transform: rotate(163deg) translate3d(0, 0, 0);
  }
  95%,
  100% {
    transform: rotate(163deg) translate3d(0, 38px, 0);
  }
}

@keyframes triangle-last {
  0%,
  87.5% {
    transform: translate3d(-120px, 0, 0);
  }
  91% {
    transform: translate3d(-80px, 0, 0);
  }
  100% {
    transform: translate3d(45px, 0, 0);
  }
}

@media screen and (max-width: 720px) {
  .logo-component {
    display: block;
    padding: auto;
    margin-bottom: auto;
    margin-top: auto;
  }
  .navbar-menu-mob-opnened {
    position: fixed;
    display: flex;
    width: 100%;
    height: 64px;
    z-index: 1;
    top: 0px;
    text-decoration: none;
    justify-content: space-between;
    right: 0;
  }
  .navbar-menu-mob {
    position: fixed;
    display: flex;
    width: 100%;
    z-index: 1;
    height: 64px;
    top: 0px;
    text-decoration: none;
    justify-content: space-between;
    right: 0;
  }
  .navbar-menu-color-mob {
    position: fixed;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 64px;
    top: 0px;
    text-decoration: none;
    justify-content: space-between;
    right: 0;
    background-color: rgba(0, 0, 0, 0.95);
  }
  .left-side {
    min-width: -webkit-fill-available;
    display: flex;
    justify-content: space-between;
  }
  .left-side .menu-icon {
    display: block;
    margin-right: 12px;
  }
  .footer-logo-desktop {
    display: none;
  }
  .links-group {
    display: flex;
    justify-content: center;
    padding: 24px;
  }
  .text-element {
    width: 100%;
    text-align: start;
    font-weight: 500;
    text-justify: auto;
  }
  .image-home {
    display: none;
  }
  .icon {
    width: 96px;
    height: 96px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }
  .image-home-mob {
    width: -webkit-fill-available;
    height: 100vh;
    background-image: url("./imgs/ny-stock.jpeg");
    align-items: center;
    background-position: center;
    background-size: cover;
    padding: 0;
  }

  .footer-component {
    width: 100%;
    height: fit-content;
    margin-top: 150px;
    background-color: #000;
    position: relative;
    bottom: 0;
    padding-right: 0;
  }
  .empire-building {
    position: absolute;
    top: -70px;
    width: 20%;
    height: auto;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo-component .footer-logo {
    display: block;
    width: 5em;
    height: 5em;
    margin-left: auto;
    margin-right: auto;
    animation: rotating 2s linear infinite;
  }
  .menu-icon {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }
  .links-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 0.95);
    height: 100vh;
    min-width: -webkit-fill-available;
    opacity: 0.7;
    padding-top: 64px;
    transition: transform 0.2s;
    transform: translateX(0%);
  }
  .links-list-opened {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 0.85);
    height: 100vh;
    min-width: -webkit-fill-available;
    padding-top: 64px;
    transition: transform 0.2s;
    transform: translateX(-100%);
    list-style: none;
  }

  .link-menu {
    font-size: medium;
    font-weight: bold;
    padding: 24px;
    border-bottom: 2px solid #fff;
    text-transform: uppercase;
  }

  .link-menu-active {
    font-size: medium;
    font-weight: bold;
    border-bottom: 2px solid #fff;
    padding: 24px;
    text-transform: uppercase;
  }
  #close-icon {
    display: flex;
    justify-content: flex-end;
    border: 2px dotted #fff;
    height: 3em;
    width: 3em;
    padding: 6px;
    border-radius: 4px;
    margin-top: 24px;
    margin-left: 70%;
    margin-top: 0%;
  }
  .body-container {
    height: fit-content;
    width: 100%;
    padding: 12px;
  }
  .body-container-home {
    height: fit-content;
  }
  .navbar-menu .link-menu {
    display: flex;
    justify-content: right;
    padding: 12px;
    text-decoration: none;
    color: #fff;
  }
}
